import styled from 'styled-components';

import { fontSize, FontSizes, fontWeight, FontWeights, minWidth, TextButton } from '@calm-web/design-system';

export const Wrapper = styled(TextButton).attrs({
	hideUnderline: true,
})`
	&& {
		${fontSize(FontSizes.xl)}
		${fontWeight(FontWeights.Medium)}
	
		${minWidth('desktop')} {
			${fontSize(FontSizes.base)}
			${fontWeight(FontWeights.Demi)}
		}
	}
`;
