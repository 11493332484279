import { useIntl } from 'react-intl';

import { Button, FocusRingVariants, FontWeights, Loader } from '@calm-web/design-system';

import Image from '@/components/image/NextImage';
import PlanSkuItems from '@/components/purchase/PlanSkuToggle/PlanSkuItems';
import PlanSkuItemsFamily from '@/components/purchase/PlanSkuToggle/PlanSkuItemsFamily';
import { useAnalytics } from '@/hooks/analytics';
import { useFormattedCurrency } from '@/hooks/purchase';
import { usePlanSkuToggle } from '@/hooks/purchase/usePlanSkuToggle';
import { usePricesState } from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import messages from '@/messages/messages';

import _messages from './messages';
import { BgContainer, Container, Copy, Heading, Wrapper } from './styles';

const PlanToggle = ({
	backgroundUrl,
	analyticsPrefix = 'Homepage',
	fadeBg = true,
}: {
	backgroundUrl: string;
	fadeBg?: boolean;
	analyticsPrefix?: string;
}) => {
	const { activePlan, setActivePlan, skus, isFamilyToggleTest, isLoading } = usePlanSkuToggle();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const prices = usePricesState();
	const formatCurrency = useFormattedCurrency();
	const routerPush = useRouterPush();

	const onClick = async () => {
		logEvent({
			eventName: `${analyticsPrefix} : CTA : Selected`,
			eventProps: {
				section: 'try_calm',
				selected_plan: activePlan,
			},
		});

		// Used in UA Tracking
		logEvent({
			eventName: 'Landing Page : Signup Flow CTA : Clicked',
		});

		await routerPush('/signup-flow', {
			source: 'homepage',
			plan: activePlan,
		});
	};

	const getHeading = () => {
		if (activePlan === 'yearly') {
			return formatMessage(_messages.heading, {
				br: <br />,
			});
		}
		if (activePlan === 'monthly') {
			return formatMessage(_messages.monthlyHeading);
		}
		if (activePlan === 'family') {
			return formatMessage(_messages.familyHeading, {
				br: <br />,
			});
		}
	};

	const getBody = () => {
		const price = formatCurrency(prices?.current?.[activePlan]);
		if (activePlan === 'yearly') {
			return formatMessage(_messages.yearlyBody, {
				price,
			});
		}
		if (activePlan === 'monthly') {
			return formatMessage(_messages.monthlyBody, {
				price,
			});
		}
		if (activePlan === 'family') {
			return formatMessage(_messages.familyBody, {
				price,
			});
		}
	};

	if (isLoading) {
		return <Loader color="gray8" />;
	}

	return (
		<Wrapper>
			<BgContainer $fadeBg={fadeBg}>
				<Image
					alt=""
					loading="lazy"
					layout="fill"
					objectFit="cover"
					objectPosition="bottom center"
					src={backgroundUrl}
				/>
			</BgContainer>
			<Container>
				<Heading>{getHeading()}</Heading>
				{isFamilyToggleTest ? (
					<PlanSkuItemsFamily skus={skus} setActivePlan={setActivePlan} />
				) : (
					<PlanSkuItems skus={skus} setActivePlan={setActivePlan} />
				)}
				<Copy>
					{getBody()}
					&nbsp;
					<a href="/terms" target="_blank" rel="noreferrer">
						{formatMessage(messages.terms)}
					</a>
					&nbsp;|&nbsp;
					<a
						href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
						target="_blank"
						rel="noreferrer"
					>
						{formatMessage(messages.cancelAnytime)}
					</a>
				</Copy>
				<Button
					fullWidth
					onPress={onClick}
					fontWeight={FontWeights.Demi}
					backgroundColor="buttonPrimaryBg"
					textColor="white"
					focusRingVariant={FocusRingVariants.Dark}
				>
					{formatMessage(messages.continue)}
				</Button>
			</Container>
		</Wrapper>
	);
};

export default PlanToggle;
