import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, FontWeights, minWidth, Text } from '@calm-web/design-system';

export const Wrapper = styled.section`
	overflow: hidden;
	width: 100%;
`;
export const ContentWrapper = styled.section`
	margin: 0 auto;
	max-width: 1440px;
	padding: 60px 20px 0;
	text-align: center;

	${minWidth('desktop')} {
		padding: 0 136px 100px;
	}
`;
export const Logos = styled.ul`
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	justify-content: center;
	grid-gap: 32px;
	margin-bottom: 60px;
	padding: 0;

	${minWidth('desktop')} {
		grid-column-gap: 60px;
		margin-bottom: 100px;
	}
`;
export const LogoItem = styled.li<{ $width: number }>`
	height: 28px;
	position: relative;
	width: ${({ $width }) => $width / 1.28}px;

	${minWidth('desktop')} {
		height: 36px;
		width: ${({ $width }) => $width}px;
	}
`;
export const Heading = styled(Text).attrs({
	el: 'h2',
	color: 'bannerText',
	noMargin: true,
})`
	&&& {
		font-size: 24px;
		margin-bottom: 32px;

		${minWidth('desktop')} {
			font-size: 36px;
			margin-bottom: 40px;
		}
	}
`;
export const ReviewItem = styled.article`
	background: ${palette('buttonPrimaryBg')};
	border-radius: 20px;
	color: ${palette('white')};
	height: 100%;
	padding: 40px 32px;
	text-align: left;
`;
export const Quote = styled(Text).attrs({
	noMargin: true,
	el: 'p',
	weight: FontWeights.Medium,
})`
	&&& {
		font-size: 20px;
		line-height: 150%;
		margin: 12px 0;

		${minWidth('desktop')} {
			font-size: 22.5px;
		}
	}
`;
export const Author = styled(Text).attrs({
	noMargin: true,
	el: 'p',
	size: FontSizes.base,
	color: 'whiteAlpha80',
})`
	&&& {
		margin-bottom: 12px;
	}
`;
