import styled, { css } from 'styled-components';

import { FontSizes, fontWeight, FontWeights, minWidth, Text } from '@calm-web/design-system';

export const Wrapper = styled.section`
	margin-bottom: 60px;
	position: relative;

	${minWidth('tablet')} {
		margin-bottom: 100px;
	}
`;
export const Container = styled.div`
	margin: 0 auto;
	max-width: 620px;
	padding: 60px 20px 256px;
	position: relative;
	text-align: center;
	z-index: 1;

	${minWidth('tablet')} {
		padding: 0 0 330px;
	}
`;
export const Heading = styled(Text).attrs({
	el: 'h2',
	color: 'bannerText',
	noMargin: true,
})`
	&&& {
		font-size: 24px;
		margin-bottom: 20px;

		${minWidth('desktop')} {
			font-size: 36px;
			margin-bottom: 24px;
		}
	}
`;
export const Copy = styled(Text).attrs({
	color: 'blackAlpha60',
	el: 'p',
	noMargin: true,
	size: FontSizes.sm,
})`
	&&& {
		${fontWeight(FontWeights.Medium)}
		margin-bottom: 20px;

		${minWidth('desktop')} {
			margin-bottom: 24px;
		}
	}
`;
export const BgContainer = styled.div<{ $fadeBg: boolean }>`
	${p =>
		p.$fadeBg &&
		css`
			mask-image: linear-gradient(
				180deg,
				rgba(247, 247, 247, 0) 0%,
				rgba(247, 247, 247, 0.0885417) 8.85%,
				#f7f7f7 100%
			);
		`};
	inset: 0;
	position: absolute;
	z-index: 0;
`;
