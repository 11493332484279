import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import FlyoutMenu from '@/components/header/FlyoutMenu';
import {
	leftNavItems,
	rightNavItems,
	SCREENING_NAV_ITEM,
} from '@/components/homepageRefresh/header/NavItems/data';
import { useUserEligibility } from '@/hooks/user/useUserEligibility';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import messages from '@/messages/messages';

import NavItemLink from '../NavItemLink';
import { HeaderNavItem } from '../NavItemLink/types';
import { MobileNavWrapper } from './styles';

const MobileMenu = ({
	isMobileMenuOpen,
	setIsMobileMenuOpen,
	onNavItemClick,
	onLoginClick,
}: {
	isMobileMenuOpen: boolean;
	setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
	onNavItemClick: (navItem: HeaderNavItem) => Promise<void>;
	onLoginClick: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { isLoggedOut, isLoggedInValid } = useUserEligibility();
	const { isEnrolled } = useAmplitudeExperiment('www-screening-header-nav');

	const mobileNavItems: HeaderNavItem[] = [
		...leftNavItems,
		...(isEnrolled ? [SCREENING_NAV_ITEM, ...rightNavItems] : rightNavItems),
	];

	const onMobileLinkClick = async (navItem: HeaderNavItem) => {
		setIsMobileMenuOpen(false);
		await onNavItemClick(navItem);
	};

	return (
		<FlyoutMenu isOpen={isMobileMenuOpen} label="Mobile navigation menu">
			<MobileNavWrapper>
				{mobileNavItems.map(navItem => (
					<li key={formatMessage(navItem.label)}>
						<NavItemLink navItem={navItem} onClick={() => onMobileLinkClick(navItem)} isOpaque={false} />
					</li>
				))}
				{isLoggedOut && (
					<li>
						<NavItemLink
							ariaLabel="Log In"
							isOpaque={false}
							onClick={onLoginClick}
							navItem={{ label: messages.loginButton }}
						/>
					</li>
				)}
				{!isLoggedOut && !isLoggedInValid && (
					<li>
						<NavItemLink
							isOpaque={false}
							onClick={() => onNavItemClick({ label: messages.continueToApp, href: '/app' })}
							navItem={{ label: messages.continueToApp, href: '/app' }}
						/>
					</li>
				)}
				{!isLoggedOut && (
					<li>
						<NavItemLink
							isOpaque={false}
							onClick={() => onNavItemClick({ label: messages.profile, href: '/app/profile' })}
							navItem={{ label: messages.profile, href: '/app/profile' }}
						/>
					</li>
				)}
			</MobileNavWrapper>
		</FlyoutMenu>
	);
};

export default MobileMenu;
