import { MessageDescriptor, useIntl } from 'react-intl';

import FiveStarMessages from '@/components/ui/StarRating/messages';

import HomepageCarousel from '../HomepageCarousel';
import FiveStars from './5-stars.svg';
import QuoteIcon from './quote-icon.svg';
import { Author, ContentWrapper, Heading, Quote, ReviewItem, Wrapper } from './styles';
import { Review } from './types';

export type TestimonialsProps = {
	reviews: Review[];
	reviewsHeading: MessageDescriptor;
};

const Testimonials = ({ reviews, reviewsHeading }: TestimonialsProps) => {
	const { formatMessage } = useIntl();

	return (
		<Wrapper>
			<ContentWrapper>
				<Heading>{formatMessage(reviewsHeading)}</Heading>
				<HomepageCarousel
					slideItems={reviews.map(review => ({
						key: formatMessage(review.author),
						Slide: () => (
							<ReviewItem>
								<QuoteIcon />
								<blockquote>
									<Quote>{formatMessage(review.quote)}</Quote>
								</blockquote>
								<Author>{formatMessage(review.author)}</Author>
								<FiveStars aria-label={formatMessage(FiveStarMessages.ariaLabel)} />
							</ReviewItem>
						),
					}))}
					numSlides={{
						desktopLarge: 3,
						desktop: 2,
						tablet: 2,
						mobile: 1,
					}}
					slideGap={{
						desktopLarge: 48,
						desktop: 36,
						tablet: 16,
						mobile: 8,
					}}
				/>
			</ContentWrapper>
		</Wrapper>
	);
};

export default Testimonials;
